
export const DFTLNGCODE =  'de';
export const URL = 'https://api.fbsshop.de';
export const IMAGEURL = '/images';
export const PAYMENTSERVER = 'https://pay.mobiserver.de:4242';
export const VENDORID = '10041001';
export const COMPANYNAME = 'Fores GmbH'
export const VERSION = "Version 2.3.4"
export const PAYMENTSYSTEM = false;
export const BANNER = false;
export const BRANDS = false;
export const FLAG_ITEM_ON_HAND = false;

export function setCurrency(currency){
    sessionStorage.setItem("currency",currency)
  }

export const getCurrency = ()=>{
return sessionStorage.getItem("currency")??"";
}

export function setDecimalDigits(decimalDigits){
    sessionStorage.setItem("decimalDigits",decimalDigits)
  }

export const getDecimalDigits = ()=>{
return sessionStorage.getItem("decimalDigits")??2;
}
